import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _99e0c036 = () => interopDefault(import('../src/pages/cookie-policy.vue' /* webpackChunkName: "pages/cookie-policy" */))
const _583c9286 = () => interopDefault(import('../src/pages/maintenance.vue' /* webpackChunkName: "pages/maintenance" */))
const _6ca3b37a = () => interopDefault(import('../src/pages/manage-booking/index.vue' /* webpackChunkName: "pages/manage-booking/index" */))
const _ce600346 = () => interopDefault(import('../src/pages/privacy-policy.vue' /* webpackChunkName: "pages/privacy-policy" */))
const _428b3462 = () => interopDefault(import('../src/pages/receipt.vue' /* webpackChunkName: "pages/receipt" */))
const _16494324 = () => interopDefault(import('../src/pages/search.vue' /* webpackChunkName: "pages/search" */))
const _494910fd = () => interopDefault(import('../src/pages/search-booking/index.vue' /* webpackChunkName: "pages/search-booking/index" */))
const _9d2f088c = () => interopDefault(import('../src/pages/terms-and-conditions.vue' /* webpackChunkName: "pages/terms-and-conditions" */))
const _6ff0f3c0 = () => interopDefault(import('../src/pages/travel-extras.vue' /* webpackChunkName: "pages/travel-extras" */))
const _151e928c = () => interopDefault(import('../src/pages/unsupported-browser.vue' /* webpackChunkName: "pages/unsupported-browser" */))
const _f9743bf6 = () => interopDefault(import('../src/pages/manage-booking/cancel/index.vue' /* webpackChunkName: "pages/manage-booking/cancel/index" */))
const _461ecf42 = () => interopDefault(import('../src/pages/manage-booking/modify.vue' /* webpackChunkName: "pages/manage-booking/modify" */))
const _3a9bd5e0 = () => interopDefault(import('../src/pages/manage-booking/receipt.vue' /* webpackChunkName: "pages/manage-booking/receipt" */))
const _223a8c74 = () => interopDefault(import('../src/pages/manage-booking/update-details.vue' /* webpackChunkName: "pages/manage-booking/update-details" */))
const _216f6ae1 = () => interopDefault(import('../src/pages/select-extras/product/index.vue' /* webpackChunkName: "pages/select-extras/product/index" */))
const _316c8dab = () => interopDefault(import('../src/pages/manage-booking/cancel/receipt.vue' /* webpackChunkName: "pages/manage-booking/cancel/receipt" */))
const _f3066b08 = () => interopDefault(import('../src/pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/cookie-policy",
    component: _99e0c036,
    name: "cookie-policy"
  }, {
    path: "/maintenance",
    component: _583c9286,
    name: "maintenance"
  }, {
    path: "/manage-booking",
    component: _6ca3b37a,
    name: "manage-booking"
  }, {
    path: "/privacy-policy",
    component: _ce600346,
    name: "privacy-policy"
  }, {
    path: "/receipt",
    component: _428b3462,
    name: "receipt"
  }, {
    path: "/search",
    component: _16494324,
    name: "search"
  }, {
    path: "/search-booking",
    component: _494910fd,
    name: "search-booking"
  }, {
    path: "/terms-and-conditions",
    component: _9d2f088c,
    name: "terms-and-conditions"
  }, {
    path: "/travel-extras",
    component: _6ff0f3c0,
    name: "travel-extras"
  }, {
    path: "/unsupported-browser",
    component: _151e928c,
    name: "unsupported-browser"
  }, {
    path: "/manage-booking/cancel",
    component: _f9743bf6,
    name: "manage-booking-cancel"
  }, {
    path: "/manage-booking/modify",
    component: _461ecf42,
    name: "manage-booking-modify"
  }, {
    path: "/manage-booking/receipt",
    component: _3a9bd5e0,
    name: "manage-booking-receipt"
  }, {
    path: "/manage-booking/update-details",
    component: _223a8c74,
    name: "manage-booking-update-details"
  }, {
    path: "/select-extras/product",
    component: _216f6ae1,
    name: "select-extras-product"
  }, {
    path: "/manage-booking/cancel/receipt",
    component: _316c8dab,
    name: "manage-booking-cancel-receipt"
  }, {
    path: "/",
    component: _f3066b08,
    name: "index"
  }, {
    path: "/dedicated",
    component: _f3066b08,
    meta: {"auth0Flow":"dedicated"},
    name: "dedicated-wl-index"
  }, {
    path: "/dedicated/search",
    component: _16494324,
    meta: {"auth0Flow":"dedicated"},
    name: "dedicated-wl-search"
  }, {
    path: "/dedicated/select-extras/product",
    component: _216f6ae1,
    meta: {"auth0Flow":"dedicated"},
    name: "dedicated-wl-select-extras"
  }, {
    path: "/dedicated/manage-booking/modify",
    component: _461ecf42,
    meta: {"auth0Flow":"dedicated"},
    name: "dedicated-wl-modify"
  }, {
    path: "/dedicated/receipt",
    component: _428b3462,
    meta: {"auth0Flow":"dedicated"},
    name: "dedicated-wl-receipt"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
